/* .styled-row:nth-of-type(even) {
    background-color: #FFFFFF;
}

.styled-row:nth-of-type(odd) {
    background-color: #F7F8F8;
} */

.styled-row {
    background-color: #FFFFFF;
    border-bottom: 2px solid #111827;
}

.styled-header {
    background-color: #111827;
    color: #FFFFFF;
    text-transform: uppercase;
}
