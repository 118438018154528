.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background-color: white;
    box-shadow: 24px;
    padding: 32px;
}

.task-name {
    color: #4A87AD;
}

.stepper {
    padding: 32px 64px;
    margin-top: 32px;
    border: 1px solid #DDE6ED;
    border-radius: 8px;
}

.footer {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 8px;
}

.balance {
    display: flex;
    padding: 30px;
    background-color: #F6F6F9;
    border-radius: 8px;
    gap: 8px;
}

.button {
    width: 300px;
}

.summary-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    background-color: #F6F6F9;
    gap: 10px;
    margin-bottom: 16px;
}

.summary-label {
    color: #8F969E;
}

.summary-row {
    display: flex;
}