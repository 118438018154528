.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    box-shadow: 24px;
    padding: 32px;
}

.blue {
    color: #4A87AD;
}

.button {
    margin-top: 8px;
    width: 300px;
}

.container-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    background-color: #F6F6F9;
    justify-content: center;
}

.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background-color: #F6F6F9;
    width: 300px;
    height: 40px;
}

.footer {
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}